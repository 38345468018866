/** Natives **/
import React, { Component } from "react";
/** Style **/
import "./LoginPage.scss";
import zohoIcon from '../../assets/zoho.png';
/* Common */
import * as Constants from '../../common/Constants';
/* Plugins */
import { withRouter } from 'react-router-dom';
/* Services */
import ApiService from '../../services/ApiService';
import DataService from '../../services/DataService';
//Class
const apiService = new ApiService();
const dataService = new DataService();

class LoginPage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading : true,
		}
	}

	//------------------------------------------------------------//
	//------------------------- DATA -----------------------------//
	//------------------------------------------------------------//

	/* Get the auth code */
	_getAuthCode = async () => {
		this._accessToPortal();
	}

	/* Access to portal */
	async _accessToPortal(){
		let accessToken = localStorage.getItem('accessToken');
		if(accessToken){
			let portalID = localStorage.getItem('portalID');

			if(portalID){
				let portalID = apiService.getZohoPortal(accessToken);
				if(portalID['error']){
					if(portalID['error']["code"] == "6401") this._refreshToken(); 
					else {
						apiService.getCode();
					}
				}else{
					this.props.history.push('/main');
				}
			}else{
				apiService.getCode();
			}
		}else{
			apiService.getCode();
		}
	}


	/* Get the access token with saved refresh token */
	async _refreshToken(){
		let refreshToken = localStorage.getItem('refreshToken')
		let res = await apiService.refreshAccess(refreshToken);

		if(res['error']){
			apiService.getCode();
		}else{
			dataService.saveTokens(res);
			this._accessToPortal();
		}
	}

	//------------------------------------------------------------//
	//------------------------ RENDER ----------------------------//
	//------------------------------------------------------------//


	render(){
		return(
			<div className="loginPage-container"> 

				<div className="login">

					{/* Logo */}
					<img src={zohoIcon} className="login-icon" />

					{/* Button */}
					<div className="login-button" onClick={this._getAuthCode}>
						S'authentifier
					</div>
				</div>
			</div>
		);
	}
}

export default withRouter(LoginPage);

