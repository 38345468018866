
import { functions }  from './FirebaseCloudService';
import credentials    from "../common/Credentials.json";
import * as constants from "../common/Constants";

class ApiService{

   //-----------------------------------------------//
   //------------------- GET -----------------------//
   //-----------------------------------------------//
   
   /* Get auth code to get access token */
   async getCode(){
      const zohoClientID = credentials[constants.ENV]['ZOHO_CLIENT_ID'];
      const redirectURI  = credentials[constants.ENV]['ZOHO_REDIRECT_ID'];

      const url = "https://accounts.zoho.com/oauth/v2/auth"
      +"?scope=ZohoProjects.portals.READ,ZohoProjects.users.READ,ZohoProjects.timesheets.READ,ZohoProjects.tasks.READ"
      +"&client_id="+zohoClientID
      +"&response_type=code"
      +"&access_type=offline"
      +"&redirect_uri="+redirectURI
      +"&prompt=consent";

      const win_logged = window.open(url, '_self');
      if (win_logged) win_logged.focus();
   }

   /* Get access token with code */
   async getAccessToken(codeOauth){
      const func_getAccess = functions.httpsCallable(constants.ENV+'_authCode');
      return func_getAccess({
         code : codeOauth,
      }).then(function(result) {
         return result;
      }, err => {
         console.log("ERROR ACCESS TOKEN : ",err);
         return err;
      })
   }
   

   /* Refresh the access */
   async refreshAccess(codeOauth){
      const refreshAccess = functions.httpsCallable(constants.ENV+'_refreshAccess');
      return refreshAccess({
         code : codeOauth,
      }).then(function(result) {
         return result['data'];
      }, err => {
         console.log("ERROR REFRESH TOKEN : ",err['data']);
         return err;
      })
   }


   /* Get access token with code */
   async getZohoPortal(accessToken){
      const func_getZohoPortal = functions.httpsCallable(constants.ENV+'_getZohoPortal');
      return func_getZohoPortal({
         code : accessToken,
      }).then(function(result) {
         return result;
      }, err => {
         console.log("ERROR GET ZOHO PORTAL : ",err);
         return err;
      })
   }


   /* Get users */
   async getZohoUsers(accessToken, portalID){
      const func_getUsers = functions.httpsCallable(constants.ENV+'_getUsers');
      return func_getUsers({
         code     : accessToken,
         portalID : portalID,
      }).then(function(result) {
         // console.log("DATA USERS > ",result['data']) 
         return result['data'];
      }, err => {
         console.log("ERROR GET ZOHO DATA : ",err);
         return err;
      })
   }


   /* Get timesheets */
   async getZohoTimesheets(accessToken, portalID, type, previousWeek){
      const func_getTimesheets = functions.httpsCallable(constants.ENV+'_getTimesheets');
      return func_getTimesheets({
         code         : accessToken,
         portalID     : portalID,
         type         : type,
         previousWeek : previousWeek
      }).then(function(result) {
         // console.log("DATA "+userID+" "+type+" => ",result['data']) 
         return result['data'];
      }, err => {
         console.log("ERROR GET ZOHO TIMESHEET : ",err);
         return err;
      })
   }

}  

export default ApiService;