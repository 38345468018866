/** Natives **/
import React, { Component } from "react";
/** Style **/
import "./ListView.scss";
/* Common */
import * as Constants from '../../common/Constants';
/** Services */
import DataService from '../../services/DataService';
const dataService = new DataService();
/* Plugins */

class ListView extends Component {
	constructor(props) {
		super(props);
		this.state = {
			weekName : Constants.CURRENT_WEEK_NAME,
			weekData : Constants.CURRENT_WEEK_DATA
		}
	}

	/* Change the view (Go to user view) */
	_changeView(){
		this.props.onChangeView(true);
	}

	/* Change week data */
	_changeWeek(){
		let {weekData, weekName} = this.state;

		let tmpWeekData;
		let tmpWeekName;

		weekData === Constants.CURRENT_WEEK_DATA ? tmpWeekData = Constants.LAST_WEEK_DATA : tmpWeekData = Constants.CURRENT_WEEK_DATA;
		weekName === Constants.CURRENT_WEEK_NAME ? tmpWeekName = Constants.LAST_WEEK_NAME : tmpWeekName = Constants.CURRENT_WEEK_NAME;

		this.setState({
			weekData : tmpWeekData,
			weekName : tmpWeekName,
		});
	}

	//------------------------------------------------------------//
	//------------------------ RENDER ----------------------------//
	//------------------------------------------------------------//

	/* Display users data */
	_displayUsers(){
		let {userData} = this.props;
		let {weekData} = this.state;

		return Object.values(userData).map((service) =>{
			return (
				<div>
					<div className="userslist-header">
						<div className="serviceName">
							{service['role']}
						</div>
						<div className="userslist-header__date">Lundi</div>
						<div className="userslist-header__date">Mardi</div>
						<div className="userslist-header__date">Mercredi</div>
						<div className="userslist-header__date">Jeudi</div>
						<div className="userslist-header__date">Vendredi</div>
						<div className="userslist-header__date">Total</div>
					</div>

					{Object.values(service['users']).map((user) => {
						let logs = Object.values(user['logsWeek'][weekData]['dates']);

						let monday 	  = dataService.getDayHour("Lundi", logs);
						let tuesday   = dataService.getDayHour("Mardi", logs);
						let wednesday = dataService.getDayHour("Mercredi", logs);
						let thursday  = dataService.getDayHour("Jeudi", logs);
						let friday    = dataService.getDayHour("Vendredi", logs);

						let totalWeek = user['logsWeek'][weekData]['totalWeek'];

						let classCompletedMon  = dataService.getClassCompleted(monday);
						let classCompletedTues = dataService.getClassCompleted(tuesday);
						let classCompletedWed  = dataService.getClassCompleted(wednesday);
						let classCompletedThur = dataService.getClassCompleted(thursday);
						let classCompletedFri  = dataService.getClassCompleted(friday);

						return <div className="userslist-user">
							<div className="userslist-user__name">{user['name']}</div>
							<div className={"userslist-user__date"+classCompletedMon}>{dataService.formatTime(monday)}</div>
							<div className={"userslist-user__date"+classCompletedTues}>{dataService.formatTime(tuesday)}</div>
							<div className={"userslist-user__date"+classCompletedWed}>{dataService.formatTime(wednesday)}</div>
							<div className={"userslist-user__date"+classCompletedThur}>{dataService.formatTime(thursday)}</div>
							<div className={"userslist-user__date"+classCompletedFri}>{dataService.formatTime(friday)}</div>
							<div className="userslist-user__date">{dataService.formatTime(totalWeek)}</div>
						</div>
					})}
				</div>
			)
	   });
	}

	render(){
		let that = this;
		let {weekName} = this.state;

		return(
			<>
				<div className="listView">
					<div className="buttonsHeader">

						{/*  Go to User view */}
						<div className="buttonsHeader-button" onClick={() => this._changeView()}>
							<i class="buttonsHeader-button__icon bx bx-user bx-sm"></i> Utilisateurs 
						</div>

						<div className="buttonsHeader-button" onClick={() => this._changeWeek()}>
							<i class="buttonsHeader-button__icon bx bx-calendar bx-sm"></i> Changer de semaine
						</div>

						<div className="buttonsHeader-title">
							{weekName}
						</div>
					</div>

					<div className="userslist">
						{that._displayUsers()}
					</div>
				</div>
			</>
		);
	}
}

export default ListView;

