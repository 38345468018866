/** Natives **/
import React, { Component } from "react";
/** Style **/
import "./Loading.scss";
/* Common */
import { ReactComponent as LogoTiz }  from '../../assets/logoTiz.svg';

class Loading extends Component {

	constructor(props) {
		super(props);
	}
	
	//------------------------------------------------------------//
	//------------------------ RENDER ----------------------------//
	//------------------------------------------------------------//

	render(){
		let {message} = this.props;

		return(
			<div className="loading">
				<div className="loading-icon"><LogoTiz /></div>
				<div className="loading-text">{message}</div>
			</div>
		);
	}
}

export default Loading;

