/** Natives **/
import React, { Component } from "react";
/** Style **/
import "./AuthPage.scss";
/* Common */
import * as Constants from '../../common/Constants';
/* Pages */
import MainPage  from '../MainPage/MainPage';
/* Plugins */
import { withRouter } from 'react-router-dom';
/* Services */
import ApiService from '../../services/ApiService';
import DataService from '../../services/DataService';
import Loading from "../../components/Loading/Loading";
//Class
const apiService = new ApiService();
const dataService = new DataService();

class AuthPage extends Component {
	_isMounted = false;

	constructor(props) {
		super(props);
		this.state = {
			message : Constants.LOADING
		}
	}

	componentDidMount(){
		let search  = window.location.search;
		if(search){
			let code = search.split('?code=').pop().split('&')[0];
			this.getTokens(code);
		}else{
			this.setState({ message : Constants.ERROR })
		}
	}

	/* Get access and refresh tokens */
	async getTokens(code){
		if(code){
			let res = await apiService.getAccessToken(code);
			let isSaved = dataService.saveTokens(res['data']);
			if(isSaved){
				this.props.history.push('/main');

			}else{
				this.setState({ message : Constants.ERROR })
			}
		}else{
			this.setState({ message : Constants.ERROR })
		}
	}
	
	//------------------------------------------------------------//
	//------------------------ RENDER ----------------------------//
	//------------------------------------------------------------//

	render(){
		let {message} = this.state;

		return(
			<Loading message={message} />
		);
	}
}

export default withRouter(AuthPage);

