import firebase from 'firebase';

// Initialize Firebase
var config = {
    apiKey: "AIzaSyDC_Qmj1qSbI4BYw9oxxy0d41AzWfuiu24",
    authDomain: "dashboard-zoho.firebaseapp.com",
    projectId: "dashboard-zoho",
    storageBucket: "dashboard-zoho.appspot.com",
    messagingSenderId: "827626938879",
    appId: "1:827626938879:web:6a3a0e388978fcb2c487ca",
    measurementId: "G-D8RPM4PCGT"
}; 

firebase.initializeApp(config);
const functions = firebase.app().functions('europe-west2');
firebase.analytics();

export {functions}