// ------------------------------------- //
// ----------- ENVIRONMENT ------------- //
// ------------------------------------- //

// export const ENV = "test";
export const ENV = "prod";

// ------------------------------------- //
// --------- LOGIN / LOADING ----------- //
// ------------------------------------- //

export const ACCES_DENIED = "Accès refusé !";
export const LOADING      = "Chargement...";
export const ERROR        = "Une erreur est survenue.";

// ------------------------------------- //
// -------------- GENERAL -------------- //
// ------------------------------------- //

export const LAST_WEEK_NAME    = "La semaine passée";
export const CURRENT_WEEK_NAME = "Cette semaine";


export const LAST_WEEK_DATA    = "previous";
export const CURRENT_WEEK_DATA = "current";
