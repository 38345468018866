import ApiService from './ApiService';
const apiService = new ApiService();

class DataService{


   /*------------------------------------------------*/
   /*------------------ STORAGE ---------------------*/
   /*------------------------------------------------*/

   /* Return the string with the first letter uppercased */
   saveTokens(res){
      let isError = res['error'];
      let accessToken  = res['access_token'];
      let refreshToken = res['refresh_token'];

      if(isError){
         console.log(isError)
         return false;
      }else{
         if(accessToken)  localStorage.setItem('accessToken', accessToken);
         if(refreshToken) localStorage.setItem('refreshToken', refreshToken);

         return true;
      }
   }


   /*------------------------------------------------*/
   /*--------------------- JSON ---------------------*/
   /*------------------------------------------------*/

   /* Get the users data */
   async getUserData(users, accessToken, portalID){
      try{
         let current_bugTS     = await apiService.getZohoTimesheets(accessToken, portalID, "bug",     false);
         let current_taskTS    = await apiService.getZohoTimesheets(accessToken, portalID, "task",    false);
         let current_generalTS = await apiService.getZohoTimesheets(accessToken, portalID, "general", false);

         let previous_bugTS     = await apiService.getZohoTimesheets(accessToken, portalID, "bug",    true);
         let previous_taskTS    = await apiService.getZohoTimesheets(accessToken, portalID, "task",   true);
         let previous_generalTS = await apiService.getZohoTimesheets(accessToken, portalID, "general",true);

         let formattedDataPromise = [];
         formattedDataPromise = this.getData(users, current_bugTS, current_taskTS, current_generalTS, previous_bugTS, previous_taskTS, previous_generalTS);

         let promiseData = await Promise.all([formattedDataPromise]);

         return promiseData[0];

      }catch (error) {
         console.error(error);
         return 'error';
      }
   }


   /* Get the data of each users */
   getData(users, current_bugTS, current_taskTS, current_generalTS, previous_bugTS, previous_taskTS, previous_generalTS){
      let formattedData = [];

      for(let user of users){
         let usrRoleName = user['role_name'];
         let userName = user['name'];
         let userMail = user['email'];

         if(userName !== "apiculteurstiz"){
            if(!formattedData[usrRoleName]) formattedData[usrRoleName] = {
               "role"  : usrRoleName,
               "users" : []
            };
   
            formattedData[usrRoleName]["users"][userName] = {
               "id"    : user['id'],
               "name"  : userName,
               "email" : userMail,
               "logsWeek" : {
                  "current" : {
                     "totalWeek" : 0,
                     "dates" : []
                  },
                  "previous" : {
                     "totalWeek" : 0,
                     "dates" : []
                  },
               }
            }

            if(current_bugTS)     formattedData = this.loopTimesheets(formattedData, "current", "bug",     current_bugTS,     user);
            if(current_taskTS)    formattedData = this.loopTimesheets(formattedData, "current", "task",    current_taskTS,    user);
            if(current_generalTS) formattedData = this.loopTimesheets(formattedData, "current", "general", current_generalTS, user);
      
            if(previous_bugTS)     formattedData = this.loopTimesheets(formattedData, "previous", "bug",     previous_bugTS,     user);
            if(previous_taskTS)    formattedData = this.loopTimesheets(formattedData, "previous", "task",    previous_taskTS,    user);
            if(previous_generalTS) formattedData = this.loopTimesheets(formattedData, "previous", "general", previous_generalTS, user);
         
            formattedData =  this.loopTotalHoursWeek(formattedData, "current",  user);
            formattedData =  this.loopTotalHoursWeek(formattedData, "previous", user);
         }
      }
      return formattedData;
   }


   /* Timesheets  */
   loopTimesheets(formattedData, week, type, logs, user){
      let usrRoleName = user['role_name'];
      let userName = user['name'];

      logs.forEach(logDate => {
         let dateStr  =  this.formatDate(logDate['date']); 
         let dateLong = ""+logDate['date']; 

         for(let log of logDate['logs']){
            if(userName === log['owner_name']){
               if(!formattedData[usrRoleName]["users"][userName]['logsWeek'][week]["dates"][dateLong]){
                  formattedData[usrRoleName]["users"][userName]['logsWeek'][week]["dates"][dateLong] = {
                     "date"     : dateStr,
                     "datelong" : dateLong,
                     "total"    : 0,
                     "logs"     : []
                  };
               }
               let totalDay = formattedData[usrRoleName]["users"][userName]['logsWeek'][week]["dates"][dateLong]['total'];
               formattedData[usrRoleName]["users"][userName]['logsWeek'][week]['dates'][dateLong]['total'] = totalDay + log['total_minutes'];
   
               let logtitle;
               if(type === "bug") logtitle = log['bug']['title'];
               else if (type === "task") logtitle = log['task']['name'];
               else logtitle = logs['name'];
   
               formattedData[usrRoleName]["users"][userName]['logsWeek'][week]['dates'][dateLong]['logs'].push({
                  "total_minutes" : log['total_minutes'],
                  "project"       : log['project']['name'], 
                  "title"         : logtitle, 
                  "notes"         : log['notes']
               })
            }
         }
      });
      return formattedData;
   }


   /* Get total hours by week */
   loopTotalHoursWeek(formattedData, weekID, user){
      let usrRoleName = user['role_name'];
      let userName = user['name'];

      let week = formattedData[usrRoleName]["users"][userName]['logsWeek'][weekID]["dates"];

      Object.values(week).forEach((days) => {
         let total = formattedData[usrRoleName]["users"][userName]['logsWeek'][weekID]["totalWeek"];
         formattedData[usrRoleName]["users"][userName]['logsWeek'][weekID]["totalWeek"] = total + days["total"]
      });
      return formattedData;
   }
   

   /*------------------------------------------------*/
   /*------------------- STRING ---------------------*/
   /*------------------------------------------------*/

   /* Return the string with the first letter uppercased */
   uppercaseFirstLetter(string){
      if(string) return string.charAt(0).toUpperCase() + string.slice(1);
   }

   /* Format date */
   formatDate(dateLog){
      const days = ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'];
      const date = new Date(dateLog); 

      const dayName = days[date.getDay()];
      const dayNbr  = date.toLocaleString('default', { day: 'numeric' });
      const month   = date.toLocaleString('default', { month: 'long' });

      return dayName+" "+dayNbr+" "+this.uppercaseFirstLetter(month)
   }

   /* Convert minutes to hours */
   formatTime(mn){
      if(mn){
         var hours = Math.trunc(mn/60);
         var minutes = mn % 60;
         if(minutes<10) minutes = "0"+minutes;
         let res = hours +"h"+ minutes;
         return res;
      }else return "";
   }

   /* Get the hour of the specified day */
   getDayHour(dayName, logs){
      let day = logs.find((day) => day.date.startsWith(dayName));
      if(day) return day.total;
      else return null
   }

   /* Get the css class to day */
   getClassCompleted(dayHour){
      if(!dayHour){
         return " --grey"
      }if(dayHour < 390){
         return " --orange"
      }else return " --blue"
   }


   /* Sort data ascending */
   sortAsc(a, b, data){
		if(a[data] < b[data]) { return -1; }
		if(a[data] > b[data]) { return 1; }
		return 0;
	}

   /* Sort data descending */
   sortDesc(a, b, data){
		if(a[data] < b[data]) { return 1; }
		if(a[data] > b[data]) { return -1; }
		return 0;
	}
}

export default DataService;