import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import 'boxicons';

/* Pages */
import LoginPage from './pages/LoginPage/LoginPage';
import AuthPage  from './pages/AuthPage/AuthPage';
import MainPage  from './pages/MainPage/MainPage';
/* Plugins */
import * as serviceWorker from './serviceWorker';
import { BrowserRouter as Router, Route } from 'react-router-dom';

const App = () => (
   <Router>
      <Route path="/" exact component={LoginPage} /> {/* LoginPage */}
      <Route path="/auth"   component={AuthPage} />
      <Route path="/main"   component={MainPage} />
   </Router>
);

ReactDOM.render(<App />, document.getElementById('root'));
serviceWorker.unregister();