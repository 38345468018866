/** Natives **/
import React, { Component } from "react";
/** Style **/
import "./MainPage.scss";
/* Common */
import * as Constants from '../../common/Constants';
/* Plugins */
import { withRouter } from 'react-router-dom';
/* Services */
import ApiService from '../../services/ApiService';
import DataService from '../../services/DataService';
import Loading from "../../components/Loading/Loading";
import UserView from "../../components/UserView/UserView";
import ListView from "../../components/ListView/ListView";
//Class
const apiService = new ApiService();
const dataService = new DataService();

class MainPage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			userData     : [],
			userSelected : null,
			isLoading    : true,
			isUserViewSelected : false,
			loadingMessage : Constants.LOADING
		}
		this._selectUser = this._selectUser.bind(this);
	}
	componentDidMount(){
		this._getPortal();
	}

	//------------------------------------------------------------//
	//-------------------------- LOG -----------------------------//
	//------------------------------------------------------------//

	/* Get the portal ID code */
	async _getPortal() {
		let accessToken = localStorage.getItem('accessToken');
		let portalID = localStorage.getItem('portalID');

		if(accessToken){
			if(portalID){
				this._getTimesheets(accessToken, portalID);
				
			}else{
				let portal = await apiService.getZohoPortal(accessToken);

				if(portal['error']){
					if(portal['error']["code"] == "6401") this._refreshToken(); 
					else {
						this.setState({ loadingMessage : Constants.ACCES_DENIED })
					}
				}else{
					portalID = portal['data'];
					localStorage.setItem('portalID', portalID);
					this._getTimesheets(accessToken, portalID);
				}
			}

		}else{
			this.props.history.push('/');
		}
	}

	/* Get the access token with saved refresh token */
	async _refreshToken(){
		let refreshToken = localStorage.getItem('refreshToken')
		let res = await apiService.refreshAccess(refreshToken);
		if(res['error']) this.setState({ loadingMessage : Constants.ACCES_DENIED })
		else{
			dataService.saveTokens(res);
			this._getPortal();
		}
	}

	//------------------------------------------------------------//
	//------------------------- DATA -----------------------------//
	//------------------------------------------------------------//

	/* Get the timesheets */
	async _getTimesheets(accessToken, portalID){
		let users = await apiService.getZohoUsers(accessToken, portalID);
		if(users['error']){
			if(users['error']["code"] == "6401") this._refreshToken(); 
			else {
				this.setState({ loadingMessage : Constants.ACCES_DENIED })
			}
		}else{
			var usersFiltered = users['users'].filter((value) => { 
				return value['role'] !== "client";
			});

			// console.log(usersFiltered)
			
			dataService.getUserData(usersFiltered, accessToken, portalID).then((userData) => {
				if(userData !== 'error'){
					let userSorted = Object.values(userData).sort((a, b) => dataService.sortDesc(a, b, "role"));
					userSorted.forEach((service) => service["users"] = Object.values(service["users"]).sort((a, b) => dataService.sortAsc(a, b, "name")));
	
					// console.log("userData SORTED  ==> ",userSorted);
		
					let firstRole = userSorted[0];
					let userSelected = Object.values(firstRole['users'])[0];
		
					this.setState({
						userData     : userSorted,
						userSelected : userSelected,
						isLoading    : false
					});
				}else{
					this.setState({ loadingMessage : Constants.ACCES_DENIED })
				}
			});
		}
	}

	/* Select a user */
	_selectUser(user)  {
		this.setState({ userSelected : user })
	}

	/* Select a user */
	_changeView(isUserViewSelected)  {
		this.setState({ isUserViewSelected : isUserViewSelected })
	}

	//------------------------------------------------------------//
	//------------------------ RENDER ----------------------------//
	//------------------------------------------------------------//


	render(){
		let { isLoading, isUserViewSelected, loadingMessage, userData, userSelected } = this.state;

		return(
			<div className="mainPage-container">
				{!isLoading && ( 
					<div className="mainPage-content">

						{isUserViewSelected &&(
							<UserView userData={userData} userSelected={userSelected} onSelectUser={(user) => this._selectUser(user)} onChangeView={(value) => this._changeView(value)}></UserView>
						)}

 						{!isUserViewSelected &&(
							<ListView userData={userData} onChangeView={(value) => this._changeView(value)}></ListView>
						)}

					</div>
				)}
				
				{/* LOADING */}
				{isLoading && (
					<Loading message={loadingMessage} />
				)} 
			</div>
		);
	}
}

export default withRouter(MainPage);

