/** Natives **/
import React, { Component } from "react";
/** Style **/
import "./UserView.scss";
/* Common */
import * as Constants from '../../common/Constants';
/** Services */
import DataService from '../../services/DataService';
const dataService = new DataService();


class UserView extends Component {

	/* Select a user */
	_selectUser(user){
		this.props.onSelectUser(user);
	}

	/* Change the view (Go to list view) */
	_changeView(){
		this.props.onChangeView(false);
	}

	//------------------------------------------------------------//
	//------------------------ RENDER ----------------------------//
	//------------------------------------------------------------//

	/* Display the list of users */
	_displayUser(that){
		let {userData, userSelected} = this.props;

		return Object.values(userData).map((service) =>{
			return (
				<div className="userslist-service">
					<div className="serviceName">
						{service['role']}
					</div>
						{Object.values(service['users']).map((user) => {
							let isSelected = false;

							if(user == userSelected) isSelected = true;
							return <div className={"userslist-service__user"+ (isSelected ? " --selected" : '')} onClick={() => that._selectUser(user)}>{user['name']}</div>
						})}
				</div>
			)
	   });
	}

	/* Display list of logs */
	_displayWeek(weekname){
		let {userSelected} = this.props;

		let week;
		if(weekname === Constants.LAST_WEEK_NAME) week = Constants.LAST_WEEK_DATA
		else week = Constants.CURRENT_WEEK_DATA

		let logsSort = Object.values(userSelected['logsWeek'][week]['dates']).sort((a, b) => {return a.datelong - b.datelong;});
		let isEmpty = logsSort.length;

		let totalWeek = userSelected['logsWeek'][week]["totalWeek"];

		return <div className="logslist-week">
			<div className="logslist-week__title">{weekname}</div>
				{isEmpty !== 0 &&(
					<>
						<div>
							{logsSort.map((log) =>  {
								return <div className="logslist-week-day">
									<div className="logslist-week-day__title">{log["date"]}</div>
									<div className="logslist-week-day__hours">{dataService.formatTime(log["total"])}</div>
								</div>
							})}
						</div>
						<div className="logslist-week-total">
							<div className="logslist-week-total__title">Total</div>
							<div className="logslist-week-total__hours">{dataService.formatTime(totalWeek)}</div>
						</div>
					</>
				)}
				
				{isEmpty == 0 &&(<div className="logslist-week">
					<div className="logslist-week-empty">
						Aucun log de temps cette semaine... aïe aïe aïe ça va chauffer !
					</div>
				</div>)}
		</div>
	}

	//------------------------------------------------------------//
	//------------------------ RENDER ----------------------------//
	//------------------------------------------------------------//

	render(){
		let {userSelected} = this.props;
		let that = this;

		return(
			<>
				{/*  List users */}
				<div className="users">
					<div className="buttonsHeader">
						<div className="buttonsHeader-button" onClick={() => this._changeView()}>
							<i class="buttonsHeader-button__icon bx bx-list-ul bx-sm"></i> Liste 
						</div>
					</div>
					<div className="userslist">
						{that._displayUser(that)}
					</div>
				</div>


				{/*  List days */}
				<div className="logs">
					<div className="logsheader">
						{userSelected['name']} 
					</div>
					<div className="logsBody">
						<div className="logslist">
							{that._displayWeek(Constants.CURRENT_WEEK_NAME)}
							{that._displayWeek(Constants.LAST_WEEK_NAME)}
						</div>
					</div>
				</div>
			</>
		);
	}
}

export default UserView;

